import { Component, OnInit, afterNextRender, afterRender } from '@angular/core';
    import { CommonModule } from '@angular/common';
    import { RouterOutlet, RouterModule, Router, NavigationEnd } from '@angular/router';
    import { UserService } from './keycloak/user.service';
    import { HeaderComponent } from "./shared/header/header.component";
    import { initFlowbite } from 'flowbite';
    import { KeycloakAngularModule } from 'keycloak-angular';
    import { BreadcrumbComponent } from "./shared/breadcrumb/breadcrumb.component";
    import { filter } from 'rxjs';
    
    @Component({
        selector: 'app-root',
        standalone: true,
        templateUrl: './app.component.html',
        styleUrl: './app.component.css',
        imports: [RouterOutlet, CommonModule, RouterModule, HeaderComponent, BreadcrumbComponent]
    })
    export class AppComponent{
      showBreadcrumb = true;
      constructor (public userService : UserService, private router: Router) {
       
        afterNextRender(() => {
          initFlowbite();
        })
        this.router.events.pipe(
          filter(event => event instanceof NavigationEnd)
        ).subscribe((event) => {
          this.showBreadcrumb = (event as NavigationEnd).url !== '/';
        });
      }
      title = 'quiz-angular';
    }
