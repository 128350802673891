<header class="fixed top-0 left-0 w-full bg-white shadow-xl z-50">
    <nav class="z-30 w-full bg-white border-b border-primary-1 dark:bg-gray-800 dark:border-gray-700 py-3 px-4 ">
        <div class="flex justify-between items-center max-w-screen-2xl mx-auto">
            <!-- Logo -->
            <div class="flex justify-start items-center">
                <a href="/" class="flex mr-14">
                    <span class="self-center hidden sm:flex text-2xl font-semibold whitespace-nowrap text-secondary dark:text-white">
                        <i class="fas fa-lightbulb"></i> SmartQuiz
                    </span>
                </a>
            </div>

            <!-- Desktop menu -->
            <div class="hidden justify-between items-center w-full lg:flex lg:w-auto">
                <ul class="flex flex-row space-x-6 text-sm font-medium">
                    <li>
                        <a href="/" class="menu-item flex items-center text-gray-700 hover:text-blue-600 transition">
                            <i class="fas fa-home mr-1 text-lg"></i> Accueil
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['list-quiz']" class="menu-item flex items-center text-gray-700 hover:text-blue-600 transition">
                            <i class="fas fa-list-ul text-lg mr-1"></i> Nos QCM
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['test-de-connaissances-saaq']" class="menu-item flex items-center text-gray-700 hover:text-blue-600 transition">
                            <i class="fas fa-car mr-1 text-lg"></i> Test SAAQ
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['trouver-une-ecole']" class="menu-item flex items-center text-gray-700 hover:text-blue-600 transition">
                            <i class="fas fa-school mr-1 text-lg text-primary"></i> Auto-écoles
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['blog']" class="menu-item flex items-center text-gray-700 hover:text-blue-600 transition">
                            <i class="fas fa-newspaper mr-1 text-lg"></i> Blog
                        </a>
                    </li>
                </ul>
            </div>

            <!-- User buttons -->
            <div class="flex items-center lg:order-2">
                @if(userService.isLoggedIn()) {
                    <!-- Code spécifique si utilisateur connecté -->
                }
                @else {
                    <a (click)="userService.subscribe()"
                       class="cursor-pointer inline-flex items-center mr-3 px-3 py-1 text-sm font-medium text-center  btn border  rounded-sm hover:bg-blue-600 hover:text-white transition">
                        <i class="fas fa-user-plus mr-1"></i> Créer un compte
                    </a>
                    <a (click)="userService.login()"
                       class="cursor-pointer inline-flex items-center px-3 py-1 text-sm font-medium text-center text-white bg-secondary rounded-sm  transition">
                        <i class="fas fa-sign-in-alt mr-1"></i> Se connecter
                    </a>
                }
                <button type="button" id="toggleMobileMenuButton"
                        class="items-center p-2 text-gray-500 rounded-lg lg:hidden hover:text-gray-900 hover:bg-gray-100 focus:ring-4 focus:ring-gray-300">
                    <span class="sr-only">Open menu</span>
                    <i class="fas fa-bars"></i>
                </button>
            </div>
        </div>
    </nav>

    <!-- Mobile menu -->
    <nav id="toggleMobileMenu" class="hidden bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700 lg:hidden">
        <ul class="flex flex-col mt-0 w-full text-sm font-medium">
            <li class="block border-b dark:border-gray-700">
                <a href="/" class="block py-3 px-4 text-gray-700 hover:text-blue-600 transition">
                    <i class="fas fa-home mr-1"></i> Accueil
                </a>
            </li>
            <li class="block border-b dark:border-gray-700">
                <a [routerLink]="['list-quiz']" class="block py-3 px-4 text-gray-700 hover:text-blue-600 transition">
                    <i class="fas fa-list-ul mr-1"></i> Nos QCM
                </a>
            </li>
            <li class="block border-b dark:border-gray-700">
                <a [routerLink]="['test-de-connaissances-saaq']" class="block py-3 px-4 text-gray-700 hover:text-blue-600 transition">
                    <i class="fas fa-car mr-1"></i> Test SAAQ
                </a>
            </li>
            <li class="block border-b dark:border-gray-700">
                <a [routerLink]="['trouver-une-ecole']" class="block py-3 px-4 text-gray-700 hover:text-blue-600 transition">
                    <i class="fas fa-school mr-1"></i> Auto-écoles
                </a>
            </li>
            <li class="block border-b dark:border-gray-700">
                <a [routerLink]="['blog']" class="block py-3 px-4 text-gray-700 hover:text-blue-600 transition">
                    <i class="fas fa-blog mr-1"></i> Blog
                </a>
            </li>
        </ul>
    </nav>
</header>
