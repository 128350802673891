import { CommonModule, isPlatformBrowser } from '@angular/common';
    import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, Renderer2, AfterViewInit } from '@angular/core';
    import { RouterModule } from '@angular/router';
    import { UserService } from '../../keycloak/user.service';
    
    @Component({
      selector: 'app-header',
      standalone: true,
      imports: [CommonModule, RouterModule],
      templateUrl: './header.component.html',
      styleUrl: './header.component.css'
    })
    export class HeaderComponent implements AfterViewInit {
      constructor(public userService: UserService, private renderer: Renderer2, private el: ElementRef, @Inject(PLATFORM_ID) private platformId: Object) {
    
      }
    
      ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId)) {
          const toggleButton = this.el.nativeElement.querySelector('#toggleMobileMenuButton');
          const mobileMenu = this.el.nativeElement.querySelector('#toggleMobileMenu');
    
          if (toggleButton && mobileMenu) {
            this.renderer.listen(toggleButton, 'click', () => {
              if (mobileMenu.classList.contains('hidden')) {
                this.renderer.removeClass(mobileMenu, 'hidden');
              } else {
                this.renderer.addClass(mobileMenu, 'hidden');
              }
            });
          }
        }
      }
    }
